/*------------------------------------*\
    #GLOBAL AFCD Styles
\*------------------------------------*/
html {
	height: 100%;
	-webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape while allowing user zoom */
}

body {
	background: $color-light-gray;
	color: $color-text-gray;
	font: 14px $font;
	width: 100%;
	height: 100%;
	overscroll-behavior: contain;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

p {
	margin: 1rem 0;
	line-height: 1.5em;
}

/*------------------------------------*\
    #LINKS
\*------------------------------------*/

/**
 * Links
 */
a {
	color: $color-text-link;
	text-decoration: none;
	font-weight: bold;
	outline: 0;

	&:hover, &:focus {
		text-decoration: underline;
		cursor: pointer;
	}
}

ul, ol {
	margin-left: 2.5rem;
}

.box {
  border: 1px solid $color-medium-gray;
  padding: 1rem;
  margin: 0 0 1rem 0;
}

.row {
	margin: 1em 0;
}

.col {
	margin-left: 1.5em;
}

/* Utility Messages */

/**
 * Show valid text color
 */
.valid {
	color: $color-utility-positive;
}

/**
 * Show error text color
 */
.error {
	color: $color-utility-negative;
	input {
		border-color: $color-utility-negative;
	}
}

.small { /* Smaller text size */
	font-size: .8em;
}

/**
 * Show warning text color
 */
.warning {
    color: $color-utility-caution;
}

/**
 * Alert boxes
 */
.information {
    color: $color-utility-neutral;
}

.alert, .infobox {
	background: $color-light-gray;
	border: 1px solid $color-medium-gray;
	padding: 1rem;
	position: relative;
	margin: 0 0 1rem 0;
	h4, p {
		margin: 0;
	}
	h4 {
		margin-right: 1.5rem;
	}
	.close {
		position: absolute;
		top: 1rem;
		right: 1rem;
		color: $color-text-gray;
	}
}

.alert.error, .infobox-error {
	background: $color-utility-negative-subtle;
	color: $color-utility-negative;
	border-color: $color-utility-negative;
}

.okta-form-input-error {
	color: $color-utility-negative;
}

.alert.success {
	background: $color-utility-positive-subtle;
	color: $color-utility-positive;
	border-color: $color-utility-positive;
}

.alert.warning {
	background: $color-utility-caution-subtle;
	color: $color-utility-caution;
	border-color: $color-utility-caution;
}

.alert.system {
  margin: 0;
	padding: 1rem;
	z-index: 999;
}

/**
 * Completely remove from the flow and screen readers.
 */
.is-hidden {
    display: none !important;
    visibility: hidden !important;
}

/**
 * Completely remove from the flow but leave available to screen readers.
 */
.is-vishidden {
    position: absolute !important;
    overflow: hidden;
    width: 1px;
    height: 1px;
    padding: 0;
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
}

hr {
	color: $color-primary-green;
}

.font-bold {
	font-weight: bold;
}

.neo-content {
	padding: 0 1rem 0 1rem;
	max-width: 1200px;
	margin: 0 auto;
}
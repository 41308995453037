/*------------------------------------*\
    #RESET
\*------------------------------------*/

/**
 * Use border box on all elements
 */
* { 
	-moz-box-sizing: border-box; 
	-webkit-box-sizing: border-box; 
	box-sizing: border-box; 
}

/**
 * Zero out margin and padding on most elements as base style
 */
html, body, div, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, ol, ul, li, form, legend, label, table, header, footer, nav, section, figure { 
	margin: 0; 
	padding: 0; 
} 

/**
 * Display block as base for these elements
 */
header, footer, nav, section, article, hgroup, figure {
	display: block;	
}
/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
//@import "~@ionic/angular/css/padding.css";
//@import "~@ionic/angular/css/float-elements.css";
//@import "~@ionic/angular/css/text-alignment.css";
//@import "~@ionic/angular/css/text-transformation.css";
//@import "~@ionic/angular/css/flex-utils.css";


/********************** Adding SCSS/sass files from pattern library/Trinity ********************************/
/******************************************************************************************************/
/******************************************************************************************************/

/*------------------------------------*\
    Google Web Fonts
\*------------------------------------*/
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300i,400,700');

/*------------------------------------*\
    $ABSTRACTS
\*------------------------------------*/
@import "./assets/css/scss/abstracts/variables";
@import "./assets/css/scss/base/reset";

/*------------------------------------*\
    $BASE
\*------------------------------------*/
@import "./assets/css/scss/base/global";
@import "./assets/css/scss/base/headings";
@import "./assets/css/scss/base/forms";
@import "./assets/css/scss/base/tables";

/*------------------------------------*\
    $COMPONENTS
\*------------------------------------*/
@import "./assets/css/scss/components/buttons";
@import "./assets/css/scss/components/overlay";
@import "./assets/css/scss/components/pagination";

/*------------------------------------*\
    $LAYOUT
\*------------------------------------*/
@import "./assets/css/scss/layout/layout";


dp-date-picker.dp-material .dp-picker-input{
    height: auto !important;
    width: auto !important;
}

/*------------------------------------*\
    #Pagination Styles
\*------------------------------------*/


.pagination {
  li {
    float: left;
    display: block;
    a {
      display: inline-block;
      padding: .5rem 1rem;
      color: $color-text-gray;
    }
    a:hover {
      background: $color-light-gray;
      cursor: pointer;
      text-decoration: none;
    }
    a.current {
      background: $color-primary-green;
      color: $color-white;
    }
  }
}

@media only screen and (max-width: 600px) {
  .pagination {
    li {
      a {
        display: none;
      }
      a.next, a.prev, a.current {
        display: inline-block;
      }
    }
    
  }
}
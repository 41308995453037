/*------------------------------------*\
    #VARIABLES
\*------------------------------------*/

/*------------------------------------*\
    #COLORS
\*------------------------------------*/

/*
 * 1) Color variables involve a two-tier system to keep colors in sync. The
 *    first tier defines the brand and neutral colors, and the second tier
 *    defines the application of those colors.
 */

/*
 * Brand Colors
 * 1) Brand color variables use English names to define colors to make palettes easier to
 *    identify. Names are generated using this tool: http://chir.ag/projects/name-that-color/
 * 2) Brand color variables should typically not be used directly in Sass partials. Instead, use
 *    second-tier application colors.
 *
 */
 $color-primary-green: #A4D65E;
 $color-primary-gray: #A9A8A9;
 $color-button-green: #5D7E20;
 $color-button-secondary: #55812C;

/*
 * Neutral Colors
 * 1) Neutral color variables use a numbered system to define variables for better maintainability.
 *    "$color-gray-10" means "10% gray", which is a gray closer to white than to black. For more info
 *    read https://medium.com/eightshapes-llc/color-in-design-systems-a1c80f65fa3#.brevrq67p
 */
$color-dark-green: #55812C;
$color-text-gray: #636466;
$color-form-gray: #d1d1d1;
$color-purple: #7F56C5;
$color-blue: #66CFE3;
$color-red: #E73D50;
$color-orange: #FFB546;
$color-black: #000;
$color-white: #FFF;
$color-light-gray: #EEE;
$color-medium-gray: #CCC;

$color-dim-25: rgba(0,0,0,0.75);
$color-dim-70: rgba(0,0,0,0.7);

/**
 * Utility Colors
 * 1) Utility colors are colors used to provide feedback, such as alert messages,
 * form validation, etc.
 */
$color-utility-positive: #03804d;
$color-utility-positive-subtle: #d4f3e6;
$color-utility-caution: #8d6708;
$color-utility-caution-subtle: #fffecf;
$color-utility-negative: #b12a0b;
$color-utility-negative-subtle: #fdded8;
$color-utility-neutral: #0192d0;
$color-utility-neutral-subtle: #d3f2ff;

 /*
  * Text Colors
  * 1) These are non-linked text colors, such as body copy.
  */

$color-text: $color-text-gray;

/*
 * Text Link Colors
 * 1) These are for linked text, such as a link found within an article,
 *    navigation link, footer link, etc.
 */
$color-text-link: #1B7F93;
$color-header-footer-link: #75D3E6;

/*------------------------------------*\
    #TYPOGRAPHY
\*------------------------------------*/

$font : 'Open Sans', sans-serif;
$font-family-base: 'Open Sans', sans-serif;
$baseFontSize: 1em;

$font-size-small : 0.75rem;
$font-size-small-2 : 0.875rem;
$font-size-med : 1rem;
$font-size-med-2: 1.0625rem;
$font-size-large : 1.4375rem;

$btn-border-radius: 6px;
$field-border-radius: 5px;
/*------------------------------------*\
    #FORMS
\*------------------------------------*/

hr {
  margin: 2.5rem 0;
}

/**
 * Fieldset base styles
 */
fieldset {
  border: 0;
  padding: 0;
  margin: 0;
}

.field {
  margin-bottom: 1.5rem;
}

/**
 * Label base styles
 */
label, .label {
  display: inline-block;
  padding-bottom: .2rem;
  font-weight: bold;
}

/**
 * Form button, input, select, and text area base styles
 * 1) Zero out margin and inherit font styles
 */
button, input, select, textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
}

/**
 * Input and text area base styles
 * 1) Width entire container, gray border, padding
 */
input, textarea, ion-input {
  width: 100%;
  border: 1px solid $color-form-gray;
  padding: .75rem;
}

input::placeholder, textarea::placeholder {
  color: $color-form-gray;
  font-style: italic;
}


/**
 * Input areas base styles
 * 1) Get rid of webkit appearance of these areas
 */
input {
  &[type='submit'] {
    width: auto;
  }

  &[type=text],
  &[type=search],
  &[type=url],
  &[type=number] {
    -webkit-appearance: none;
  }

  &[type=number] {
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

textarea {
  -webkit-appearance: none;
}


.dropdown {
  position: relative;
  background: #fff;
  border: 1px solid $color-form-gray;
  border-radius: 5px;
  z-index: 0;

  .arrow {
    position: absolute;
    right: .5em;
    top: .25em;
    color: $color-medium-gray;
    z-index: 1;
  }

  select {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    text-indent: 10px !important;
    appearance: none !important;
    color: $color-text-gray !important;
    width: 100%;
    position: relative;

    background: none;
    border: 0;
    padding: .75em 0 !important;
    z-index: 2;

    &::-ms-expand {
      display: none;
    }
  }

}

/**
 * Button/Submit button base styles
 */
button, input[type="submit"] {
  cursor: pointer;
}

.search {
  position: relative;
  max-width: 600px;
  margin: 2rem 0;

  input, ion-input {
    text-indent: 2rem;
  }

  .fa-search {
    position: absolute;
    top: .75rem;
    left: .75rem;
  }
}

.amount {
  position: relative;

  input {
    text-indent: 1rem;
  }

  .fa-dollar-sign {
    position: absolute;
    top: 2.75rem;
    left: .75rem;
  }
}

/**
 * Search cancel button and search decoration webkit appearance none
 */
input[type="search"] {
  &::-webkit-search-cancel-button,
  &::-webkit-search-decoration {
    -webkit-appearance: none;
  }
}


/************************
 Custom Checkbox Styles ***************************/
.custom-checkbox {
  position: relative;
  margin-bottom: 1rem;
  -webkit-tap-highlight-color: transparent;

  label {
    // Creating checkbox outer container with psuedo element
    &::before {
      content: "";
      display: inline-block;
      height: 20px;
      width: 20px;
      border: 1px solid $color-medium-gray;
      background: $color-light-gray;
      position: relative;
      top: 5px;
      margin: 0 .5em 0 0;
    }

    // Creating checkmark with psuedo element
    &::after {
      content: "";
      display: inline-block;
      height: 6px;
      width: 11px;
      border-left: 3px solid;
      border-bottom: 3px solid;
      position: absolute;
      left: 4px;
      top: 8px;
      color: #fff;
      transform: rotate(-45deg);
    }


    &:hover {
      cursor: pointer;

      // Hover color change
      &::before {
        background: $color-medium-gray;
      }
    }
  }

  input {
    &[type="checkbox"] {
      opacity: 0;
      position: absolute;

      //Adding focus styles on the outer-box of the fake checkbox
      &:focus + label::before {
        outline: rgb(59, 153, 252) auto 5px;
      }

      // Hide the checkmark by default
      + label::after {
        content: none;
      }

      // Unhide the checkmark on the checked state
      &:checked {
        + label::after {
          content: "";
        }

        // change background color on checked state
        + label::before {
          background: $color-primary-green;
          border: 1px solid $color-dark-green;
        }
      }
    }

    // Styles for Disabled Checkboxes
    &[disabled] {
      + label::before {
        opacity: .5;
      }

      + label:hover {
        cursor: not-allowed;
      }

      + label:hover::before {
        background: $color-light-gray;
      }
    }
  }
}


// Custom Radio buttons styles
label.radio {
  margin-bottom: 1rem;
}

.custom-radio {
  position: relative;

  input[type="radio"] {
    opacity: 0;
    position: absolute;

    // Hide the checkmark by default
    + label::after {
      content: none;
    }

    // Unhide the checkmark on the checked state
    &:checked {
      + label::after {
        content: "";
      }

      // change background color on checked state
      + label::before {
        background: #a4d65e;
        border: 1px solid #6ca438;
      }
    }
  }

  /* Creating outer background */
  label::before {
    content: "";
    display: inline-block;
    height: 20px;
    width: 20px;
    border: 1px solid #d1d1d1;
    border-radius: 50%;
    background: #eee;
    position: relative;
    top: 5px;
    margin: 0 .5em 0 0;
  }

  /* Creating inner radio indicator */
  label::after {
    content: "";
    display: inline-block;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background: #fff;
    position: absolute;
    left: 7px;
    top: 12px;
  }

  /* Hover color change */
  label:hover::before {
    background: $color-medium-gray;
  }

  label:hover {
    cursor: pointer;
  }
}


/**********************
	datepicker
	*****************************/
.ng-valid {
  .ngx-datepicker-container {
    position: relative;

    .fa-calendar-alt {
      position: absolute;
      right: .5rem;
      top: .75rem;
      display: inline-block;
      visibility: visible;
    }

    .day-unit.is-today {
      color: #fff !important;
    }

    .is-disabled {
      opacity: .25;
    }

    .is-today {
      opacity: 1;
    }

    .day-unit.is-selected {
      background: $color-dark-green !important;
    }

    input {
      width: 100% !important;
      border: 1px solid $color-form-gray !important;
      padding: .75rem !important;
      margin: 0;
      font-size: 1rem !important;

      &:hover, .fa-calendar-alt:hover {
        cursor: pointer;
      }
    }

    .ngx-datepicker-calendar-container {
      z-index: 3;
    }
  }
}

.ngx-datepicker-container {
  .fa-calendar-alt {
    display: none;
    visibility: hidden;
  }

  input {
    border: 0 !important;
    margin: 0;
  }
}

@media only screen and (max-width: 600px) {
  input[type='submit'] {
    width: 100%;
  }
}

/*------------------------------------*\
    #HEADINGS
\*------------------------------------*/

h1, h2, h3, h4, h5, h6 {
	margin: 1rem 0;
	font-family: 'Montserrat', sans-serif;
}

/**
 * Heading 1
 */
h1  {
	font-size: 2rem;
	line-height: 1.2;
}

/**
 * Heading 2
 */
h2 {
	font-size: 1.5rem;
	line-height: 1.2;
	border-left: 6px solid $color-primary-green;
	padding-left: .75rem;
	a {
		color: $color-text-gray;
	}
}

/**
 * Heading 3
 */
h3  {
	font-size: 1.25rem;
	line-height: 1.2;
}

/**
 * Heading 4
 */
h4  {
	font-size: 1rem;
}

/**
 * Heading 5
 */
h5 {
	font-size: 1rem;
}

/**
 * Heading 6
 */
h6 {
	font-size: .75rem;
}

@media only screen and (max-width: 600px) {
  h1 {
    font-size: 1.75rem;
  }
  h2 {
    font-size: 1.5rem;
  }
  h3 {
    font-size: 1.25rem;
  }
	h4 {
		font-size: 1rem;
	}
	h5 {
		font-size: .75;
	}
	h6 {
		font-size: .75;
	}
}
/*------------------------------------*\
    #LAYOUT
\*------------------------------------*/

.flexContainer {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.headerWrap, .footerWrap, .flexContent {
	max-width: 1200px;
	width: 100%;
}

.flexContent {
	flex: 1 0 auto;
	margin: 0 auto;
	box-shadow: 0px 0px 12px #CCC;
	border-width: 0 1px;
	padding-bottom: 2.5rem;
	background: #FFF;
}

.flexShrinkZero {
	flex-shrink: 0;
}

.content {
	padding: 1rem;
	flex-grow: 1;
	
	main {
		margin: 0 2rem 0 0;
	}
	aside {
		width: 100%;
		max-width: 400px;
	}
}

.content.center {
	max-width: 600px;
	margin: 0 auto;
}

.flex-row {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
}

.flex-row-even {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.flex-row-center {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.flex-row-right {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;
	.btn {
		margin: 1rem 0 0 1rem;
	}
}

.flex-col {
	display: flex;
	flex-direction: column;
}

.flex-end-align {
	justify-content: flex-end;
}

.sticky.top {
	position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.sticky.bottom {
	position: -webkit-sticky;
  position: sticky;
  bottom: 0;
}

.top-margin {
	margin-top: 1.5em;
}

.bottom-margin {
	margin-bottom: 1.5em;
}

@media only screen and (max-width: 600px) {
	.content {
		flex-direction: column;
		main {
			margin: 0;
		}
	}
	.flex-row-right {
		.btn {
			margin: 1rem 0 0 0;
		}
	}
}

.sudo-bootstrap {
	.row {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		margin: 0;
	}
	// this will apply to screens with 1200px width screens or greater
	@media only screen and (min-width: 1200px) {
		.col-1 {
			width: 8.3%;
		}
		.col-2 {
			width: 16.6%;
		}
		.col-3 {
			width: 25%;
		}
		.col-4 {
			width: 33.33%;
		}
		.col-5 {
			width: 41.66%;
		}
		.col-6 {
			width: 50%;
		}
		.col-7 {
			width: 58.33%;
		}
		.col-8 {
			width: 66.66%;
		}
		.col-9 {
			width: 75%;
		}
		.col-10 {
			width: 83.33%;
		}
		.col-11 {
			width: 91.66%;
		}
		.col-12 {
			width: 100%;
		}
		.col-1-offset {
			margin-left: 8.3%;
		}
		.col-2-offset {
			margin-left: 16.6%;
		}
		.col-3-offset {
			margin-left: 25%;
		}
		.col-4-offset {
			margin-left: 33.33%;
		}
		.col-5-offset {
			margin-left: 41.66%;
		}
		.col-6-offset {
			margin-left: 50%;
		}
		.col-7-offset {
			margin-left: 58.33%;
		}
		.col-8-offset {
			margin-left: 66.66%;
		}
		.col-9-offset {
			margin-left: 75%;
		}
		.col-10-offset {
			margin-left: 83.33%;
		}
		.col-11-offset {
			margin-left: 91.66%;
		}
		.col-12-offset {
			margin-left: 100%;
		}
	}

	// this will apply to screens width between (inclusively) 751-1199
	@media only screen and (min-width: 751px) and (max-width: 1199px) {
		.col-md-1 {
			width: 8.3%;
		}
		.col-md-2 {
			width: 16.6%;
		}
		.col-md-3 {
			width: 25%;
		}
		.col-md-4 {
			width: 33.33%;
		}
		.col-md-5 {
			width: 41.66%;
		}
		.col-md-6 {
			width: 50%;
		}
		.col-md-7 {
			width: 58.33%;
		}
		.col-md-8 {
			width: 66.66%;
		}
		.col-md-9 {
			width: 75%;
		}
		.col-md-10 {
			width: 83.33%;
		}
		.col-md-11 {
			width: 91.66%;
		}
		.col-md-12 {
			width: 100%;
		}
		.col-md-1-offset {
			margin-left: 8.3%;
		}
		.col-md-2-offset {
			margin-left: 16.6%;
		}
		.col-md-3-offset {
			margin-left: 25%;
		}
		.col-md-4-offset {
			margin-left: 33.33%;
		}
		.col-md-5-offset {
			margin-left: 41.66%;
		}
		.col-md-6-offset {
			margin-left: 50%;
		}
		.col-md-7-offset {
			margin-left: 58.33%;
		}
		.col-md-8-offset {
			margin-left: 66.66%;
		}
		.col-md-9-offset {
			margin-left: 75%;
		}
		.col-md-10-offset {
			margin-left: 83.33%;
		}
		.col-md-11-offset {
			margin-left: 91.66%;
		}
		.col-md-12-offset {
			margin-left: 100%;
		}
	}

	// this will apply to screens with 750px width screens or smaller
	@media only screen and (max-width: 750px) {
		.col-sm-1 {
			width: 8.3%;
		}
		.col-sm-2 {
			width: 16.6%;
		}
		.col-sm-3 {
			width: 25%;
		}
		.col-sm-4 {
			width: 33.33%;
		}
		.col-sm-5 {
			width: 41.66%;
		}
		.col-sm-6 {
			width: 50%;
		}
		.col-sm-7 {
			width: 58.33%;
		}
		.col-sm-8 {
			width: 66.66%;
		}
		.col-sm-9 {
			width: 75%;
		}
		.col-sm-10 {
			width: 83.33%;
		}
		.col-sm-11 {
			width: 91.66%;
		}
		.col-sm-12 {
			width: 100%;
		}
		.col-sm-1-offset {
			margin-left: 8.3%;
		}
		.col-sm-2-offset {
			margin-left: 16.6%;
		}
		.col-sm-3-offset {
			margin-left: 25%;
		}
		.col-sm-4-offset {
			margin-left: 33.33%;
		}
		.col-sm-5-offset {
			margin-left: 41.66%;
		}
		.col-sm-6-offset {
			margin-left: 50%;
		}
		.col-sm-7-offset {
			margin-left: 58.33%;
		}
		.col-sm-8-offset {
			margin-left: 66.66%;
		}
		.col-sm-9-offset {
			margin-left: 75%;
		}
		.col-sm-10-offset {
			margin-left: 83.33%;
		}
		.col-sm-11-offset {
			margin-left: 91.66%;
		}
		.col-sm-12-offset {
			margin-left: 100%;
		}
	}
}
.modal-padding{
	padding: 10px
}
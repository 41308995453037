/*------------------------------------*\
    #TABLE
\*------------------------------------*/

td.label {
  font-weight: bold;
}

/****** Table custom styles **********/
table {

  /**
 * Table base styles
 */
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid $color-primary-gray;
  width: 100%;
  table-layout: fixed;
  font-size: .8em;

  caption {
    font-size: 1.5rem;
    margin: .5em;
    text-align: left;
  }

  th {
    background: $color-text-gray;
    color: $color-white;
    text-align: left;
  }

  td, th {
    padding: .5em;
  }

  tr:nth-child(even) {
    background: $color-light-gray;
  }

  tr:nth-child(odd) {
    background: #FFF;
  }
}


@media only screen and (min-width: 751px) {
  .width110 {
    width: 110px;
  }

  .width200 {
    width: 200px;
  }
}

@media (hover: none), screen and (max-width: 750px) {
  table {
    border: 0;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 2rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    text-align: right;
  }

  .payment-activity table td {
    text-align: left;

    .table-content {
      margin-top: 1.75em;
    }
  }

  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
  }

  table td:last-child {
    border-bottom: 0;
  }
}

/*------------------------------------*\
    #Overlay Styles
\*------------------------------------*/


.overlay {
	background: #FFF;
	border: 1px solid $color-light-gray;
	padding: 1.5rem;
	box-shadow: 6px 6px 12px #666;
}
/*------------------------------------*\
    #BUTTONS
\*------------------------------------*/


.btn, .button, .btn.secondary.selected {
	padding: .5rem 2rem;
	margin: 1rem 1rem 1rem 0;
	background: $color-button-green;
	color: #FFF;
	font-weight: bold;
	border: 2px solid $color-button-green;
	border-radius: $btn-border-radius;
	box-shadow: none;
	display: inline-block;
	-webkit-appearance: none;
}

.btn:hover, .button:hover, .btn.secondary:hover, a.btn:hover, a.btn.secondary:hover, .btn.selected {
	//background: darken($color-primary-green, 15%);
	background: #4A5D18;
	border: 2px solid $color-dark-green;
	color: $color-white;
	text-decoration: none;
	cursor: pointer;
}

.btn.secondary {
	background: $color-white;
	border-color: $color-button-secondary;
	color: $color-button-secondary;
}

.btn.inactive {
	background: $color-primary-gray;
	border-color: $color-primary-gray;
	color: $color-white;
	cursor: not-allowed;
}

.btn.danger {
	padding: .5rem 2rem;
	margin: 1rem 1rem 1rem 0;
	background: red;
	color: #FFF;
	font-weight: bold;
	border: 2px solid red;
	border-radius: $btn-border-radius;
}

.btn.secondary.inactive {
	background: $color-white;
	border-color: $color-primary-gray;
	color: $color-primary-gray;
	cursor: not-allowed;
}

.toggle {
	cursor: pointer;
}